<template>
  <div class="dish">
    <div v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="1" :offset="22">
          <el-button icon="el-icon-printer" size="small" @click="print" />
        </el-col>
        <el-col :span="8">
          <el-upload
            class="avatar-uploader"
            :http-request="uploadFile"
            action=""
            :show-file-list="false"
            :disabled="!isAvailable"
          >
            <img v-if="dish.image" :src="dish.image" class="image" :alt="dish.title" />
            <div v-else class="avatar-uploader-icon">
              <i class="el-icon-plus" />
            </div>
          </el-upload>
        </el-col>
        <el-col :span="16">
          <el-row :gutter="20">
            <el-col
              v-for="(input, index) in fieldsInputs"
              :key="index"
              :span="input.span"
              class="mb-5"
            >
              <dish-item-input
                :field-name="input.name"
                :field-label="input.lable"
                :field-value.sync="dish[input.name]"
                :visible-value="input.visibleValue ? dish[input.visibleValue] : null"
                :type="input.type"
                :search-fn="input.searchFn"
                @change-field="changeField"
              />
            </el-col>
            <el-col :span="24">
              <div class="is-flex is-justify-content-flex-end mb-6">
                <el-button v-if="isAvailable" type="success"> Cохранить тех карту </el-button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row>
        <el-col :span="12">
          <h3 class="title is-4">Ингредиенты</h3>
        </el-col>
        <el-col :span="12">
          <div class="is-flex is-justify-content-flex-end is-align-items-center mb-4">
            <el-button
              v-if="isAvailable"
              type="primary"
              icon="el-icon-plus"
              @click="showAddIngredient"
            >
              Добавить ингредиент
            </el-button>
            <el-button v-if="isAvailable" type="primary" icon="el-icon-plus" @click="showAddSemis">
              Добавить полуфабрикат
            </el-button>
          </div>
        </el-col>
      </el-row>
      <dish-ingredient-table
        v-if="dish.ingredients"
        :show-add-ingredient="isShowIngredient"
        :show-add-semis="isShowSemis"
        :data.sync="dish.ingredients"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { FETCH_MEALS } from '@/app/settings/meals/shared/state/types/action_types';
import { DATA } from '@/app/settings/meals/shared/state/types/getter_types';
import { SELECTED, LOADING } from '../shared/state/types/getter_types';
import { SELECT_DISH, UPDATE_DISH, PRINT_DISH } from '../shared/state/types/action_types';
import DishItemInput from './dish-item-prop/dish-item-input.vue';
import DishIngredientTable from './dish-ingredient-table/dish-ingredient-table.vue';

export default {
  name: 'Dish',
  components: { DishItemInput, DishIngredientTable },
  data() {
    return {
      fieldsInputs: [
        { name: 'title', lable: 'Название', span: 12, type: 'input' },
        {
          name: 'meal_id',
          lable: 'Категория приема пищи',
          visibleValue: 'meal',
          span: 12,
          type: 'autocomplete',
          searchFn: this.searchFn,
        },
        { name: 'barcode', lable: 'Штрихкод', span: 12, type: 'input' },
        { name: 'workshop', lable: 'Цех', span: 12, type: 'input' },
        { name: 'tax', lable: 'Налог', span: 12, type: 'input' },
        { name: 'cooking_time', lable: 'Время приготовления', span: 12, type: 'time' },
        { name: 'color', lable: 'Цвет', span: 12, type: 'color' },
        { name: 'cooking_process', lable: 'Процесс приготовления', span: 12, type: 'textarea' },
      ],
      isShowIngredient: false,
      isShowSemis: false,
      kbju: null,
    };
  },
  computed: {
    ...mapGetters({
      dish: SELECTED,
      meals: DATA,
      loading: LOADING,
    }),
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
  },
  async mounted() {
    await this.select(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      select: SELECT_DISH,
      update: UPDATE_DISH,
      fetchMeals: FETCH_MEALS,
    }),
    async changeField() {
      await this.update(this.dish);
    },
    async sendDataIngredients() {
      this.loading = true;
      try {
        await this.$store.dispatch('STORE_DISH_INGREDIENT', { ingredient_id: this.ingredientId });
        this.$notify({
          title: 'Успешно',
          message: 'Ингредиент успешно добавлен',
          duration: 1500,
          type: 'success',
        });
      } catch (error) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Ошибка',
          duration: 1500,
        });
      }
      await this.handleClose();
      this.loading = false;
      return true;
    },
    async uploadFile(e) {
      const { file } = e;
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.$store.dispatch('UPLOAD_FILE', formData);
      this.dish.image = `${response.path}`;
      await this.changeField();
    },
    async searchFn(queryString, cb) {
      const filter = {};
      if (queryString) {
        filter.title = queryString;
      }
      await this.fetchMeals({ filter });
      cb(this.meals.data);
    },
    showAddSemis() {
      if (this.isShowIngredient) {
        this.isShowIngredient = false;
      }
      this.isShowSemis = !this.isShowSemis;
    },
    showAddIngredient() {
      if (this.isShowSemis) {
        this.isShowSemis = false;
      }
      this.isShowIngredient = !this.isShowIngredient;
    },
    print() {
      const { id } = this.$route.params;
      this.$store.dispatch(PRINT_DISH, id);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
.image {
  width: 350px;
  height: 350px;
  object-fit: contain;
}
.avatar-uploader {
  height: 300px;
  .el-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 100px;
  }
}
</style>
