<template>
  <div class="dish-item-input">
    <div class="is-flex is-align-items-center">
      <span class="has-text-info mr-2">{{ fieldLabel }}: </span>
      <el-link
        v-if="isAvailable"
        class="edit-link-primary"
        type="primary"
        icon="el-icon-edit"
        @click="editField"
      />
    </div>
    <span
      v-if="show"
      :style="{
        color: type === 'color' ? value : null,
      }"
      class="mt-2"
      @dblclick="isAvailable ? editField : () => {}"
    >
      <span v-if="visibleValue || values.title">
        {{ visibleValue ? visibleValue.title : values.title ? values.title : null }}
      </span>
      <span v-else> {{ value ? value : 'Не указанно' }}</span>
    </span>
    <div v-else class="is-flex mt-2">
      <el-input
        v-if="type === 'input'"
        v-model="value"
        size="small"
        autosize
        @keyup.enter.native="sendDataUpdate(fieldName)"
      />
      <el-input v-else-if="type === 'textarea'" v-model="value" type="textarea" :rows="2" />
      <el-color-picker v-else-if="type === 'color'" v-model="value" />
      <el-time-select
        v-else-if="type === 'time'"
        v-model="value"
        :picker-options="{
          start: '00:00',
          step: '00:05',
          end: '23:55',
        }"
      />
      <el-autocomplete
        v-else-if="type === 'autocomplete'"
        v-model="visible.title"
        size="small"
        prefix-icon="el-icon-search"
        style="width: 100%"
        :fetch-suggestions="searchFn"
        :debounce="600"
        placeholder="Найти"
        :value-key="valueKey"
        @select="selectFn"
      />
      <el-button
        type="success"
        class="ml-2 edit-link-success"
        size="mini"
        icon="el-icon-check"
        :underline="false"
        @click="sendDataUpdate(fieldName)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DishItemInput',
  props: {
    fieldLabel: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      default: '',
    },
    fieldValue: {
      default: '',
      type: [String, Number, Object],
    },
    type: {
      type: String,
      default: 'input',
    },
    valueKey: {
      type: String,
      default: 'title',
    },
    visibleValue: {
      type: Object,
      default: null,
    },
    searchFn: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      meal: null,
      values: { title: null },
    };
  },
  computed: {
    value: {
      get() {
        return this.fieldValue;
      },
      set(value) {
        this.$emit('update:field-value', value);
      },
    },
    visible: {
      get() {
        return this.visibleValue ? this.visibleValue : this.values;
      },
      set(value) {
        this.$emit('update:visible-value', value);
      },
    },
    ...mapState({
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    sendDataUpdate() {
      this.$emit('change-field', this.value);
      this.show = !this.show;
    },
    editField() {
      this.show = !this.show;
    },
    selectFn(value) {
      this.value = value.id;
    },
  },
};
</script>
<style>
.edit-link-success {
  font-weight: bold;
}
</style>
