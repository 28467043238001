<template>
  <el-form
    v-if="show"
    ref="ingredient-dish"
    :model="ingredientDish"
    label-position="top"
    class="mb-4"
    @submit.prevent.native="saveFn"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-autocomplete
          v-model="ingredientDish.ingredient.title"
          prefix-icon="el-icon-search"
          style="width: 100%"
          :fetch-suggestions="searchSemisFn"
          :debounce="600"
          placeholder="Найти полуфабрикат"
          value-key="title"
          @select="selectSemisFn"
        />
      </el-col>
      <el-col :span="10">
        <div class="is-flex is-align-items-center">
          <el-input
            v-model="ingredientDish.brutto"
            style="width: 90%"
            class="mr-3"
            placeholder="Укажите кол-во полуфабриката"
          />
          <span style="font-weight: bold">{{ 'гр.' }}</span>
        </div>
      </el-col>
      <el-col :span="2">
        <el-button type="success" size="small" class="mt-1" icon="el-icon-plus" @click="saveFn" />
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FETCH_SEMIFINISHEDS } from '@/app/administration/semifisheds/shared/state/types/action_types';

export default {
  name: 'AddSemis',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ingredientDish: {
        ingredient: {
          title: null,
          id: null,
          unit: {
            title: null,
          },
        },
        count: null,
      },
    };
  },
  computed: {
    ...mapState({
      semis: (state) => state.semifishedsState.semisData.data,
    }),
  },
  methods: {
    ...mapActions({
      fetchSemis: FETCH_SEMIFINISHEDS,
    }),
    selectSemisFn(semis) {
      this.ingredientDish.ingredient = semis;
    },
    async searchSemisFn(queryString, cb) {
      const filter = {};
      if (queryString) {
        filter.title = queryString;
      }
      await this.fetchSemis({ filter });
      cb(this.semis.data);
    },
    saveFn() {
      this.$emit('add-semis', {
        semis_id: this.ingredientDish.ingredient.id,
        brutto: this.ingredientDish.brutto,
        netto: this.ingredientDish.brutto,
        count: this.ingredientDish.brutto,
      });
    },
  },
};
</script>
