<template>
  <div class="dish-ingredient-table">
    <add-ingredient :show="showAddIngredient" @add-ingredient="saveFn" />
    <add-semis :show="showAddSemis" @add-semis="saveSemisFn" />
    <el-table
      ref="dish-ingredient-table"
      v-loading="loading"
      :data="dataIngredients"
      style="width: 100%"
    >
      <el-table-column
        prop="ingredient.title"
        :width="isAvailable ? 180 : 'auto'"
        label="Название"
      />
      <el-table-column v-if="isAvailable" label="Методы">
        <template slot-scope="scope">
          <column-methods :methods="scope.row" @selectMethod="changeIngredientSize" />
        </template>
      </el-table-column>
      <el-table-column prop="count" :width="isAvailable ? 200 : 'auto'" label="Брутто">
        <template slot-scope="scope">
          <column-brutto
            :brutto="scope.row.brutto"
            :unit="scope.row.ingredient.unit ? scope.row.ingredient.unit : { id: 1 }"
          />
        </template>
      </el-table-column>
      <el-table-column prop="count" :width="isAvailable ? 200 : 'auto'" label="Нетто">
        <template slot-scope="scope">
          <column-netto
            v-if="isAvailable"
            :ingredient-id="scope.row.ingredient.id"
            :semis="scope.row.ingredient.ingredients"
            :netto.sync="scope.row.netto"
            :count.sync="scope.row.count"
            :unit="scope.row.ingredient.unit ? scope.row.ingredient.unit : { id: 1 }"
            @changeNetto="changeIngredientNetto"
          />
          <column-brutto
            v-else
            :brutto="scope.row.netto"
            :unit="scope.row.ingredient.unit ? scope.row.ingredient.unit : { id: 1 }"
          />
        </template>
      </el-table-column>
      <el-table-column v-if="isAvailable" prop="rules" :width="70">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <energy-value
      v-if="dish"
      :calories="dish.calories"
      :proteins="dish.proteins"
      :fats="dish.fats"
      :carbohydrates="dish.carbohydrates"
      :netto="dish.netto"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  DELETE_DISH_INGREDIENT,
  UPDATE_DISH_INGREDIENT,
  STORE_DISH_INGREDIENT,
} from '@/app/settings/dishes/shared/state/types/action_types';
import { FETCH_INGREDIENTS } from '@/app/administration/ingredients/shared/state/types/action_types';
import { notifyMixin } from '@/app/settings/dishes/shared/mixins/notify-mixin';
import AddIngredient from '@/app/administration/semifisheds/semifished/semifished-ingredient-table/add-ingredient.vue';
import ColumnMethods from '@/app/administration/semifisheds/semifished/semifished-ingredient-table/table-columns/column-methods.vue';
import ColumnBrutto from '@/app/administration/semifisheds/semifished/semifished-ingredient-table/table-columns/column-brutto.vue';
import ColumnNetto from '@/app/administration/semifisheds/semifished/semifished-ingredient-table/table-columns/column-netto.vue';
import EnergyValue from '@/app/administration/semifisheds/semifished/semifished-ingredient-table/energy-value.vue';
import AddSemis from './add-semis.vue';

export default {
  name: 'DishIngredientTable',
  components: { AddIngredient, AddSemis, ColumnMethods, ColumnBrutto, ColumnNetto, EnergyValue },
  mixins: [notifyMixin],
  props: {
    data: {
      type: Array,
      required: false,
      default: null,
    },
    showAddIngredient: {
      type: Boolean,
      default: false,
    },
    showAddSemis: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formRules: {
        title: [{ required: true, message: 'Введите название', trigger: 'blur' }],
      },

      calories: 0,
      fats: 0,
      proteins: 0,
      carbohydrates: 0,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.dishesState.dishData.loading,
      ingredients: (state) => state.ingredientsState.ingredientsData.data,
      semis: (state) => state.semifishedsState.semisData.data,
      dish: (state) => state.dishesState.dishData.selected,
      error: (state) => state.dishesState.dishData.error,
      isAvailable: (state) =>
        ['super-admin', 'master-admin'].includes(state.authState.authState.role),
    }),
    dataIngredients: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit('update:data', value);
      },
    },
  },
  watch: {
    error(value) {
      this.showErrorMessage(value.response.data.message);
    },
  },
  methods: {
    ...mapActions({
      delete: DELETE_DISH_INGREDIENT,
      update: UPDATE_DISH_INGREDIENT,
      store: STORE_DISH_INGREDIENT,
      fetchIngredients: FETCH_INGREDIENTS,
    }),
    formatedNetto(item) {
      if (Number(item) > 1000) {
        const kg = Number(item) / 1000;
        return `${Number(kg).toFixed(3)} кг`;
      }
      return Number(item) > 1
        ? `${Number(item).toFixed(3)} гр`
        : `${Number(item).toPrecision(2)} гр`;
    },
    formatedKbju(item) {
      return Number(item) > 1 ? Number(item).toFixed(2) : Number(item).toPrecision(2);
    },
    getUnit(unit) {
      return unit.id === 1 ? 'гр.' : 'шт.';
    },
    convertUnit(unit, value) {
      if (unit.id === 1) {
        return value * 1000;
      }
      return value;
    },
    handleDelete(row) {
      const action = row.ingredient.fats !== undefined ? 'ingredient' : 'semis';
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete({ id: row.ingredient.id, action });
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Ингредиент успешно удален');
        })
        .catch(() => {});
    },
    async changeIngredientSize(dataIngredient) {
      await this.update(dataIngredient);
    },
    async saveFn(dataIngredient) {
      await this.store(dataIngredient);
      this.showSuccessMessage('Успешно добавлен');
    },
    async saveSemisFn(semisData) {
      await this.store(semisData);
      this.showSuccessMessage('Успешно добавлен');
    },
    selectIngredientFn(ingredient) {
      this.ingredientDish.ingredient = ingredient;
    },
    async searchIngredientsFn(queryString, cb) {
      const filter = {};
      if (queryString) {
        filter.title = queryString;
      }
      await this.fetchIngredients({ filter });
      cb(this.ingredients.data);
    },

    async changeIngredientNetto(dataNetto) {
      await this.update(dataNetto);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-grey {
  background: #f5f7fa;
}
</style>
